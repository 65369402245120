import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AppLayout from "./components/layouts";
import AppRoutes from "./components/routes";
import Login from "./components/components/login";
import loadIcons from "./loadIcons";
import { useEffect } from "react";
import "./instrument";

loadIcons();

function App() {
  const isAuthenticated = localStorage.getItem("token") !== null;
  let lng = localStorage.getItem("default-lg");

  useEffect(() => {
    if (!lng) {
      localStorage.setItem("default-lg", "ar");
    }
  }, [lng]);
  return (
    <>
      {isAuthenticated ? (
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      ) : (
        <Routes>
          <Route
            path="*"
            exact={true}
            element={<Navigate replace to="/login" />}
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
    </>
  );
}

export default App;
