import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPop = ({ text, isOpen, setIsOpen, route, children }) => {
  const navigate = useNavigate();
  return (
    <section
      className={`z-[1000] relative ${isOpen ? "block" : "hidden"}`}
      onClick={() => {
        setIsOpen(false);
        route && navigate(route);
      }}
    >
      <div className="bg-[#00000099] fixed w-full h-screen right-0 top-0 cursor-pointer" />
      <div className="z-[70] fixed border border-[#DDE5E9] rounded-[8.75px] bg-white w-[90%] md:w-[70%] xl:w-[428px] h-[163px] flex flex-col justify-center items-center top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2">
        <img src="/icons/success-icon.svg" alt="icon " />
        <p className="text-[#292D32] text-sm font-bold">{text}</p>
        {children && children}
      </div>
    </section>
  );
};

export default SuccessPop;
