import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { protectedRoutes } from "./routes";
import { LANGUAGE } from "services/general";

const importComponent = (path) => {
  return lazy(() => import(`../${path}`));
};

const protectedRouteComponents = protectedRoutes.map(({ path, filePath }) => ({
  path: `/:type/${path.toLowerCase()}`,
  component: importComponent(`components/${filePath}`),
}));

const AppRoutes = () => {
  return (
    <Routes>
      {protectedRouteComponents.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense
              fallback={
                <div className="spain-container">
                  <div>loading ...</div>
                </div>
              }
            >
              <route.component />
            </Suspense>
          }
        />
      ))}

      <Route
        path="*"
        exact={true}
        element={<Navigate replace to={`/${LANGUAGE}/home`} />}
      />
    </Routes>
  );
};
export default AppRoutes;
