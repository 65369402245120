import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Logout,
  Magnifier,
  Messages,
  Notifications,
  Setting,
  HomeIcon,
  Toggle,
} from "../shared/icons";
import customFetch from "../../services/api";
import { useTranslation } from "react-i18next";

const Navbar = ({ isOpen, setIsOpen }) => {
  let { i18n, t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handelLogout = async () => {
    try {
      await customFetch("dashboard/admins/logout", { method: "POST" });
      localStorage.removeItem("token");
      navigate("/login");
      window.location.reload();
    } catch (error) {}
  };
  const changeLng = () => {
    const newLanguage = i18n.language === "ar" ? "en" : "ar";
    localStorage.setItem("default-lg", newLanguage);
    i18n.changeLanguage(newLanguage);

    const newPath = pathname.replace(/^\/[^/]+/, `/${newLanguage}`);
    navigate(newPath);
    window.location.reload();
  };

  return (
    <nav className="ps-[25px] pe-[30px] py-[19px] flex justify-between items-center border-b border-[#E8E8E8] gradient-nav-bg top-0 w-full z-50">
      <Toggle onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
      <section className={`flex items-center ${isOpen ? "gap-40" : "gap-10"}`}>
        <Link to={"/"} className="relative z-[100]">
          <img
            src="/logo.png"
            alt="amer logo"
            className={`object-contain w-[90px] md:w-auto`}
          />
        </Link>
        <div className="bg-white px-[15px] rounded-[9px] w-[400px] justify-between items-center search-shadow border border-[#F1F1F2] lg:flex hidden">
          <div className="flex items-center gap-[10px]">
            <Magnifier />
            <input
              type="text"
              placeholder={t("search_placeholder")}
              className="w-[310px] h-[48px] focus:outline-none text-[13px] leading-[14px] font-medium placeholder:text-[#A1A5B7]"
            />
          </div>
          <Setting />
        </div>
      </section>
      <div className="flex items-center gap-2 md:gap-[20px] ">
        <Messages />
        <HomeIcon />
        <Notifications />
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgq8me1EL_WBvjktsl9sdTxLDTNtSJNuN-JBQ3dggq_A&s"
          alt="user profile picture"
          className="h-[36px] w-[36px] rounded-[9px] object-cover cursor-pointer"
        />
        <Link onClick={changeLng}>{i18n.language == "en" ? "AR" : "EN"}</Link>
        <Logout onClick={handelLogout} />
      </div>
    </nav>
  );
};

export default Navbar;
