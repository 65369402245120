import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Meta = ({ title, desc }) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{`${t("amer")} | ${title}`}</title>
      <meta name="description" content={desc} />
    </Helmet>
  );
};

export default Meta;
