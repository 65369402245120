import i18next from "i18next";
import lanAr from "./locale/lanAr.json";
import lanEn from "./locale/lanEn.json";
import { initReactI18next } from "react-i18next";

let lg = localStorage.getItem("default-lg");

const resources = {
  en: {
    translation: lanEn,
  },
  ar: {
    translation: lanAr,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: lg || "ar",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
