import { library } from "@fortawesome/fontawesome-svg-core";

import * as solidIcons from "@fortawesome/free-solid-svg-icons";

const loadIcons = () => {
  const solidIconList = Object.keys(solidIcons)
    .filter((key) => key !== "fas" && key !== "prefix")
    .map((iconKey) => solidIcons[iconKey]);

  library.add(...solidIconList);
};

export default loadIcons;
