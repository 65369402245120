import { LANGUAGE } from "services/general";
import {
  Accounts,
  ActiveContractsIcon,
  ActiveHomeIcon,
  ActiveInve,
  ActiveOrdersIcon,
  ActiveSettingsIcon,
  ActiveSupport,
  ActiveUnitsIcon,
  ActiveUserIcon,
  ContractsIcon,
  HelpCenter,
  HomeIcon,
  Inventory,
  Missions,
  SettingsIcon,
  UnitsIcon,
  UserIcon,
} from "../components/shared/icons";

export const asideLinks = [
  {
    text: "الرئيسية",
    en_text: "Home",
    path: `/${LANGUAGE}/home`,
    icon: <HomeIcon />,
    activeIcon: <ActiveHomeIcon />,
  },
  {
    text: "العملاء",
    en_text: "Customers",
    path: `/${LANGUAGE}/customers`,
    icon: <UserIcon />,
    activeIcon: <ActiveUserIcon />,
  },
  {
    text: "الموظفين",
    en_text: "Employees",
    path: `/${LANGUAGE}/employees`,
    icon: <UserIcon />,
    activeIcon: <ActiveUserIcon />,
  },
  {
    text: "العقارات و المرافق",
    en_text: "Properties & Facilities",
    path: `/${LANGUAGE}/properties`,
    icon: <UnitsIcon />,
    activeIcon: <ActiveUnitsIcon />,
  },
  {
    text: "الوحدات",
    en_text: "Units",
    path: `/${LANGUAGE}/units`,
    icon: <UnitsIcon />,
    activeIcon: <ActiveUnitsIcon />,
  },
  {
    text: "المخازن",
    en_text: "Inventory",
    path: `/${LANGUAGE}/inventory`,
    icon: <Inventory />,
    activeIcon: <ActiveInve />,
  },
  {
    text: "التقارير",
    en_text: "Reports",
    path: `/${LANGUAGE}/reports`,
    icon: <Inventory />,
    activeIcon: <ActiveInve />,
  },
  {
    text: "الأرصدة و الحسابات",
    en_text: "Balances & Accounts",
    path: "/",
    icon: <Missions />,
    activeIcon: <Missions />,
    children: [
      {
        text: "السندات",
        en_text: "Bonds",
        path: `/${LANGUAGE}/sanadat`,
      },
      {
        text: "القيود",
        en_text: "Entries",
        path: `/${LANGUAGE}/financial-entries`,
      },

      {
        text: "الحسابات",
        en_text: "Financial Trees",
        path: `/${LANGUAGE}/financial-trees`,
      },
      {
        text: "القوائم الماليه",
        en_text: "Financial Statements",
        path: `/${LANGUAGE}/financial-statements`,
      },
    ],
  },
  {
    text: "المشتريات",
    en_text: "Purchases",
    path: "/",
    icon: <Missions />,
    activeIcon: <Missions />,
    children: [
      {
        text: "فواتير الشراء",
        en_text: "Purchase Invoices",
        path: `/${LANGUAGE}/purchase-invoices`,
      },
      {
        text: "طلب امر الشراء",
        en_text: "Purchase order request",
        path: `/${LANGUAGE}/purchase-order-request`,
      },
      {
        text: "فواتير مرتجع مشتريات",
        en_text: "Purchase return invoices",
        path: `/${LANGUAGE}/purchase-return-invoices`,
      },
    ],
  },
  {
    text: "المبيعات",
    en_text: "Sales",
    path: "/",
    icon: <Missions />,
    activeIcon: <Missions />,
    children: [
      {
        text: "فواتير المبيعات",
        en_text: "Invoices",
        path: `/${LANGUAGE}/sales-invoices`,
      },
      {
        text: "فواتير مرتجع المبيعات",
        en_text: "Invoices",
        path: `/${LANGUAGE}/sales-return-invoices`,
      },
    ],
  },
  {
    text: "العقود",
    en_text: "Contracts",
    path: `/${LANGUAGE}/contracts`,
    icon: <ContractsIcon />,
    activeIcon: <ActiveContractsIcon />,
  },
  {
    text: "مركز المساعدة",
    en_text: "Support Center",
    path: `/${LANGUAGE}/support-center`,
    icon: <HelpCenter />,
    activeIcon: <ActiveSupport />,
  },
  {
    text: "الإعدادات",
    en_text: "Settings",
    path: `/${LANGUAGE}/settings`,
    icon: <SettingsIcon />,
    activeIcon: <ActiveSettingsIcon />,
  },
  {
    text: "قواعد البيانات",
    en_text: "Databases",
    icon: <Accounts />,
    activeIcon: <Accounts />,
    path: "/",
    children: [
      {
        text: "الخدمات",
        en_text: "Services",
        path: `/${LANGUAGE}/services`,
      },
      {
        text: "الشركات",
        en_text: "Entities",
        path: `/${LANGUAGE}/entities`,
      },
      {
        text: "قوالب شروط العقود",
        en_text: "Contract Conditions Templates",
        path: `/${LANGUAGE}/contracts-conditions`,
      },
      {
        text: "الدرجه الوظيفيه",
        en_text: "Job Positions",
        path: `/${LANGUAGE}/employee-positions`,
      },
      {
        text: "أنواع المخازن",
        en_text: "Inventory Types",
        path: `/${LANGUAGE}/inventory-types`,
      },
      {
        text: "المهام",
        en_text: "Tasks",
        path: `/${LANGUAGE}/tasks`,
      },
      {
        text: "الملاحظات",
        en_text: "Notes",
        path: `/${LANGUAGE}/notes`,
      },
    ],
  },
];
