import { translate } from "services/general";

export const duesOrders = [
  { label: "محصله", value: "collected" },
  { label: "متأخرة", value: "delayed" },
  { label: "مجدوله", value: "scheduled" },
];
export const transactionTypes = [
  { label: translate("نقدي", "Cash"), value: "cash" },
  { label: translate("شيك", "Cheque"), value: "cheque" },
  { label: translate("تحويل بنكي", "Wiretransfer"), value: "wiretransfer" },
  { label: translate("عبر الإنترنت", "Online"), value: "online" },
];
export const EXPENSE_TO = [
  { name: "مستاجر", en_name: "Customer", value: "customer" },
  { name: "شركه", en_name: "Entity", value: "entity" },
];
export const SANADAT_TYPES = [
  { label: "debit", en_name: "debit", value: "debit" },
  { label: "credit", en_name: "credit", value: "credit" },
];
export const SANADAT_BTNS = [
  {
    text: "sanadat.btns.btn0",
    type: "",
  },
  {
    text: "sanadat.btns.btn1",
    type: "payment",
  },
  {
    text: "sanadat.btns.btn2",
    type: "receipt",
  },
];
export const REF_TYPES = [
  { en_label: "Entity", label: "شركه", value: "entity" },
  { en_label: "Customer", label: "موظف", value: "customer" },
];

export const INVOICE_SALES_TYPES = [
  { en_label: "Sales", label: "فاتوره مبيعات ", value: "sales" },
  { en_label: "Sales Refund", label: "مرتجع مبيعات", value: "s_refund" },
];
export const INVOICE_PURCHASE_TYPES = [
  { en_label: "Purchase", label: "فاتوره مشتريات", value: "purchase" },
  { en_label: "rpo", label: "طلب امر شراء", value: "rpo" },
  { en_label: "Purchase Refund", label: "مرتجع شراء", value: "p_refund" },
  { en_label: "Po", label: "طلب شراء", value: "po" },
];

export const FINANCIAL_TREE_TYPES = [
  { label: "استثماري", en_label: "Operating", value: "Operating" },
  { label: "تشغيلي", en_label: "Investing", value: "Investing" },
  { label: "محاسبي", en_label: "Financing", value: "Financing" },
];
export const ERROR_DATA = { status: false };

export const IDENTAL_TYPES = [
  { label: translate("جواز السفر", "Passport"), value: "passport" },
  { label: translate("الهوية الوطنية", "National"), value: "national" },
  { label: translate("الدولية", "International"), value: "international" },
];
export const EMPLOYEES_STATUS = [
  { label: translate("فعال", "Active"), value: "1" },
  { label: translate("غير فعال", "Unactive"), value: "0" },
];

export const PROPERTY_TYPES = [
  { label: translate("ايجار", "ٌRent"), value: "rent" },
  { label: translate("بيع", "Sale"), value: "sale" },
];
export const PROPERTY_STATS = [
  { label: translate("مشغوله", "Busy"), value: "busy" },
  { label: translate("خالية", "Free"), value: "free" },
];
export const STOCK_OPTIONS = [
  { label: translate("يوجد", "In Stock"), value: "one" },
  { label: translate("لا يوجد", "Out Of Stock"), value: "zero" },
];
