import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (window.location.hostname !== "localhost") {
  import("./instrument").then(() => {
    import("@sentry/react").then((Sentry) => {
      Sentry.init({
        dsn: "https://dc66a6a55cd68d1932dc8ba9e8e075ab@o4504095208898560.ingest.us.sentry.io/4507582342365184",
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    });
  });
}
