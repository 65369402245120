import React from "react";
import { useTranslation } from "react-i18next";

const RightSwiper = () => {
  const { t } = useTranslation();
  return (
    <section className="w-1/2 h-screen lg:block hidden ps-[7%] bg-[#F6FAFB] ">
      <img
        src="/logo-login.svg"
        alt="amer logo"
        className="absolute right-[7%] top-[4vh] Amer-logo"
      />
      <div className="h-screen mySwiper relative ">
        <div className="flex flex-col justify-center h-full ps-1 pt-14 3xl:pt-0">
          <img
            src="/swiper-image.png"
            className="w-[290px] 2xl:w-[300px] 3xl:w-[390px] object-cover object-right-bottom"
          />
          <div className="mt-[1vh] text-[#161313]">
            <h1 className="font-bold 2xl:text-[36px] text-[33px] leading-[58px] mb-[3px]">
              {t("welcome")}
            </h1>
            <div className="2xl:text-[20px] text-lg opacity-70 w-[85%]">
              <p className="2xl:leading-[30px] leading-6">
                {t("welcome_desc1")}
              </p>
              <p className="2xl:leading-[30px] leading-6">
                {t("welcome_desc2")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightSwiper;
