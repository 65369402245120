import { LANGUAGE } from "services/general";

const dev = {
  API_ENDPOINT_URL: `https://amer-laravel.detailslocal.com/api/${LANGUAGE}/`,
  SITE_URL: "https://fal-logistics.detailslocal.com/",
};

const prod = {
  API_ENDPOINT_URL: `https://logistics-backend.arabianfal.net/api/${LANGUAGE}/`,
  SITE_URL: `https://logistics.arabianfal.net/`,
};

const test = {
  API_ENDPOINT_URL: `https://amer-laravel.detailslocal.com/api/${LANGUAGE}/`,
  SITE_URL: "http://localhost:3000/",
};

const getEnv = () => {
  if (window.location.href.search("localhost") >= 0) {
    return test;
  }

  if (window.location.href.search("detailslocal.") >= 0) {
    return dev;
  }

  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
