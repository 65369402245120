import { ERROR_DATA } from "constants/general";
import { API_BASE_URL } from "../configs/AppConfig";
import { handleErrors } from "./errors";

// const BASE_URL = API_BASE_URL;
// const BASE_URL = "https://amer-laravel.detailslocal.com/api/en/";
let BASE_URL = API_BASE_URL;

// if (window.location.hostname === "localhost") {
//   BASE_URL = "http://127.0.0.1:8000/api/ar/";
// }

const customFetch = async (url, options = {}) => {
  const jwtToken = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };

  let requestBody;

  if (options.body) {
    if (options.body instanceof FormData) {
      requestBody = new FormData();
      options.body.forEach((value, key) => {
        if (value !== undefined && value !== "undefined") {
          requestBody.append(key, value);
        }
      });
    } else {
      requestBody = Object.entries(options.body).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== "undefined") {
          acc[key] = value;
        }
        return acc;
      }, {});
      requestBody = JSON.stringify(requestBody);
      headers["Content-Type"] = "application/json";
    }
  }

  const response = await fetch(`${BASE_URL}${url}`, {
    method: options.method || "GET",
    headers: headers,
    body: requestBody,
  });

  const contentType = response.headers.get("content-type");

  let res;
  if (options.responseType === "blob") {
    return await response.blob();
  }
  if (contentType.includes("application/json")) {
    res = await response.json();
  } else if (contentType.includes("text/html")) {
    res = await response.text();
  } else {
    res = await response.text();
  }

  if (res.status === false) {
    handleErrors(res);
    return ERROR_DATA;
  } else {
    return res;
  }
};

// Example usage

export default customFetch;
