import RightSwiper from "./RightSwiper";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import Meta from "services/Meta";

const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center h-screen lg:h-auto">
      <Meta title={t("pages.login")} />
      <RightSwiper />
      <div className="flex-col justify-center items-center lg:w-1/2 w-full lg:block">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
